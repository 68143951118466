@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,300&display=swap');

//font
$Poppins: 'Poppins', sans-serif;


//color
$bg-color: #252525;
$text-color:#fff;
$accent-color: #F0F41F;
$btn-color: #252525;