@import './variables.scss';


body{
    margin: 0;
    background-color: $bg-color;
    font-family: $Poppins;
}

h1{
    font-size: 34px;
    font-weight: 500;
    line-height: 40px;
    color:white;
}
h2{
    color:white;
    font-weight: 500;
}

h3{
    font-size:14px;
    font-weight: 400;
    color:$accent-color
}

h4{
    font-size:14px;
    font-weight: 400;
    color:white
}

h5{
    margin: 8px 0;
    font-size: 600;
    color:white
}

p{
    font-weight: 400;
    line-height: 23px;
    color:white;
    font-size: 12px;
}

button{
    background-color: $accent-color;
    color:$bg-color;
    box-shadow: 0px 5px 10px #54550f;
    height: 50px;
    width: 200px;
    border-radius: 10px;
    font-size: 18px;
    font-family: $Poppins;
    font-weight: 600;
    letter-spacing: -.5px;
    border: none;
    transition: 0.3s;
    &:hover{
        cursor: pointer;
        background-color:#bfc21b ;
    }
}
select{
    background-color: transparent;
    font-size: 14px;
    font-family: $Poppins;
    font-weight: 600;
    color:$accent-color;
    border-radius: 5px;
    height: 37px;
    padding:0 10px;
    border-color: $accent-color;
    transition: 0.3s;
    &:focus{
        outline: none;
    }
    &:hover{
        color:$bg-color;
        background-color: $accent-color;
    }
}

option{
    background-color: $bg-color;
    color: $accent-color;
    &:hover{
        background-color:$accent-color
        
    }
}

.main-container{
    padding:0 40px;
}

.flex{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    &-wrap{
        flex: 1 0 25%;
        padding:20px 5px
    }
}

.filter{
    float: right;
    display: inline-flex;
    justify-items: end;
    align-items: center;
}

// @media screen and (max-width: 426px){
//     .movie > .poster{
//         width: 90%;
//     }
// }