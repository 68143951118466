@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,300&display=swap);
body{margin:0;background-color:#252525;font-family:"Poppins",sans-serif}h1{font-size:34px;font-weight:500;line-height:40px;color:white}h2{color:white;font-weight:500}h3{font-size:14px;font-weight:400;color:#F0F41F}h4{font-size:14px;font-weight:400;color:white}h5{margin:8px 0;font-size:600;color:white}p{font-weight:400;line-height:23px;color:white;font-size:12px}button{background-color:#F0F41F;color:#252525;box-shadow:0px 5px 10px #54550f;height:50px;width:200px;border-radius:10px;font-size:18px;font-family:"Poppins",sans-serif;font-weight:600;letter-spacing:-.5px;border:none;transition:0.3s}button:hover{cursor:pointer;background-color:#bfc21b}select{background-color:transparent;font-size:14px;font-family:"Poppins",sans-serif;font-weight:600;color:#F0F41F;border-radius:5px;height:37px;padding:0 10px;border-color:#F0F41F;transition:0.3s}select:focus{outline:none}select:hover{color:#252525;background-color:#F0F41F}option{background-color:#252525;color:#F0F41F}option:hover{background-color:#F0F41F}.main-container{padding:0 40px}.flex{display:-webkit-flex;display:flex;-webkit-flex-wrap:wrap;flex-wrap:wrap;-webkit-justify-content:center;justify-content:center}.flex-wrap{-webkit-flex:1 0 25%;flex:1 0 25%;padding:20px 5px}.filter{float:right;display:-webkit-inline-flex;display:inline-flex;justify-items:end;-webkit-align-items:center;align-items:center}

.main-container{display:grid;grid-template-columns:1fr}

@use "sass:color";.movie-card{display:grid;justify-items:center;-webkit-align-items:start;align-items:start;-webkit-flex:1 0 21%;flex:1 0 21%;margin-top:60px}.movie-card h4{margin:15px 20px;text-align:center}.movie-rating{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;max-height:60px}.movie-img{width:300px;height:100%;overflow:hidden;transition:0.3s}.movie-img img{display:block;width:100%;border-radius:20px;min-width:100%}.movie-img:hover{cursor:pointer;opacity:0.6}.movie-title{height:100%}

.movie{bottom:0;position:fixed;left:0;top:0;display:-webkit-flex;display:flex;height:100vh;width:100%;z-index:3;background-color:rgba(20,20,20,0.9);-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center}.movie>.poster{border-radius:10px;display:-webkit-flex;display:flex;z-index:5;width:50%;max-height:90vh;-webkit-backdrop-filter:blur(10px);backdrop-filter:blur(10px);position:relative;background-color:rgba(0,0,0,0.5);z-index:5;overflow-y:hidden;-webkit-flex-direction:column;flex-direction:column;-webkit-justify-content:center;justify-content:center}.movie>.poster>.detail{overflow:auto;border-radius:10px}.movie>.poster>.detail>.info{padding:40px}.movie>.poster>.detail>.info>.i-1{display:-webkit-flex;display:flex;-webkit-flex-direction:row;flex-direction:row;-webkit-align-items:center;align-items:center;padding-bottom:20px}.movie>.poster>.detail>.info>.cta{text-align:center;padding-top:40px}.movie img{width:100%;max-height:50%}.lang{font-weight:600;color:white;border:white solid;border-radius:5px;padding:0 3px;width:20px;text-align:center}.genres{display:-webkit-flex;display:flex;-webkit-flex-direction:row;flex-direction:row}.genres>.genre{background-color:#F0F41F;border-radius:40px;height:100%;font-size:11px;font-weight:400;color:#252525;padding:5px 10px;margin-right:5px}@media screen and (max-width: 426px){.movie>.poster{width:90%}}

.Nav-container{display:-webkit-flex;display:flex;height:60px;-webkit-justify-content:space-between;justify-content:space-between}.Nav-container a{text-decoration:none;font-size:18px;color:#F0F41F}.Nav-container a img{height:10px}.Nav-logo,.Nav-items{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center}.Nav-logo:hover,.Nav-items:hover{cursor:pointer}

