@import './variables.scss';

.Nav{

    &-container{
        display: flex;
        height: 60px;
        justify-content: space-between;

        a{
            text-decoration: none;
            font-size: 18px;
            color:$accent-color;
            
            img{
                height: 10px;
            }
        }

    }
    &-logo,&-items{
        display: flex;
        align-items: center;

        &:hover{
            cursor: pointer;
        }
    }

}   