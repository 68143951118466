@use "sass:color";

.movie{
    &-card{
        display: grid;
        justify-items: center;
        align-items: start;
        flex: 1 0 21%;
        margin-top: 60px;
        h4{
            margin: 15px 20px;
            text-align: center;
        }
    }
    &-rating{
        display: flex;
        align-items: center;
        max-height:60px;  
    }
    &-img{
        width:300px;
        height:100%;    
        overflow:hidden;
        transition: 0.3s;

        img{
            display: block;
            width:100%;
            border-radius: 20px;
            min-width:100%;
        }
        &:hover{
            cursor: pointer;
            opacity: 0.6;
        }
    }

    &-title{
        height: 100%;
    }

}