@import './variables.scss';

.movie{
    bottom: 0;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    height: 100vh;
    width: 100%;
    z-index: 3;
    background-color: rgba(20, 20, 20, 0.9);
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &>.poster{
        border-radius: 10px;
        display: flex;
        z-index: 5;
        width: 50%;
        max-height: 90vh;
        backdrop-filter: blur(10px);
        // bottom: 0;
        position: relative;
        // right: 0;
        // top: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 5;
        overflow-y: hidden;
        flex-direction: column;
        justify-content: center;

        
        &>.detail{
            overflow: auto;
            border-radius: 10px;
                &>.info{
                    padding: 40px;

                    &>.i-1{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding-bottom: 20px ;
                    }
                    
                    &>.cta{
                        text-align: center;
                        padding-top: 40px;

                    }

                }
            }
        }

    
    & img{
        width: 100%;
        max-height: 50%;
    }
}

.lang{
    font-weight: 600;
    color: white;
    border: white solid;
    border-radius: 5px;
    padding: 0 3px;
    width: 20px;
    text-align: center;
}

.genres{
    
    display: flex;
    flex-direction: row;

    &>.genre{
        background-color: $accent-color;
        border-radius:40px;
        height: 100%;
        font-size: 11px;
        font-weight: 400;
        color: $bg-color;
        padding: 5px 10px;
        margin-right: 5px;

    }
}

@media screen and (max-width: 426px){
    .movie > .poster{
        width: 90%;
    }
}